$(function () {
    $('#offset').val(10);
    $(".read-more-link").click(function () {
        $(".content-body").toggleClass("reveal-closed").toggleClass("reveal-open");
    });

    $('.search-form').toggle();
    $('.fa-times').toggle();

    //you-may-also-like
    $(".likeOptions").hide();
    var categoryToShow = $("#category_id").val();
    $("." + categoryToShow).show();
    $('.moreShare').toggle();
    $('.item-comments').toggle();
    $('.scroll-down-nav').hide();
    $('.scroll-up-nav').hide();
});

$(window).on('scroll', function () {
    if ($(window).scrollTop() >= 60) {
        $('.scroll-up-nav').fadeIn(500);
    }
    else {
        $('.scroll-up-nav').fadeOut(500);
    }
});

//you-may-also-like
$(document).on('change', "#category_id", function () {
    var categoryToShow = $(this).val();
    $(".likeOptions").hide();
    $("." + categoryToShow).show();

});

//Video and Story read more
$('.read-more-link').click(function (e) {
    e.stopPropagation();
    $('#item').animate({'height': $('#item')[0].scrollHeight});
    $('.from-around-web').show();
    $('.share-it').show();
    $('.read-more-link').hide();
    $('.item-overlay').hide();
});

$('.btn-more-post').click(function (e) {
    e.stopPropagation();
    var siteUrl = location.origin;
    var offset = $('#offset').val();
    var editUrl = siteUrl + "/authors/more-authors-posts/" + $(this).attr("id") + "/" + offset;

    $.get(editUrl, function (data) {
        if (data) {
            $('#authorPostItems').append(data.dataView);
            $('#offset').val(parseInt(offset) + 10);
            $(document).scrollTop($('.btn-more-post').offset().top - $('#authorPostItems').offset().top);
        }
    });
});

$('.moreShareLink').click(function (e) {
    e.preventDefault();
    $('.moreShare').toggle();
});

$('.read-fb-comments').click(function (e) {
    e.preventDefault();
    $('.item-comments').toggle();
});

$(".alphabet-letter").click(function (e) {
    e.stopPropagation();
    var siteUrl = location.origin;
    var editUrl = siteUrl + "/tags/filter/" + this.id;
    $.get(editUrl, function (data) {
        if (data) {
            $(".tags-for-letter").empty();
            $(".tags-for-letter").append(data.dataView);
        }
    });
});

$(".search-icon").click(function () {
    $('.search-form').toggle();
    $('.fa-times').toggle();
    $('.search-icon').toggle();
});

$(".fa-times").click(function () {
    $('.search-form').toggle();
    $('.fa-times').toggle();
    $('.search-icon').toggle();
});